import type { GraphQLError, GraphQLFormattedError } from 'graphql'

export const ignoredGraphQLErrorMessages = [/is not authenticated/]

export const ignoredGraphQLErrorCodes = [
  'RECORD_NOT_FOUND',
  'AUTHORIZATION_ERROR',
  'INTEGRATION_DISABLED',
]

export const isWhitelistedGraphQLError = (error: GraphQLError | GraphQLFormattedError) =>
  ignoredGraphQLErrorMessages.some((msg) => error.message.match(msg)) ||
  ignoredGraphQLErrorCodes.some((code) => error.extensions?.code === code)
