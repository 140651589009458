import {
  CouponPaymentFrequency,
  FipBeneficiary,
  Month,
  PaymentTermMode,
  PpaStatus,
  PricingScheme,
  SettlementMarket,
  SettlementPricingZone,
  SpvCompanyType,
} from 'generated/tensor-api/generated-types'
import { ValueAssumption } from 'typ'

export const PAGINATED_LIST_SIZES = [10, 20, 40] as const

export const INTRADAY_CHART_SELECTION = ['candlestick', 'confidence-bar'] as const

export const INTRADAY_DATA_OPTIONS = ['high-low-prices', 'contracted-volume'] as const

export const ANALYTICS_MARKETS_TABS = [
  'day-ahead',
  'intraday',
  'imbalance',
  // 'certificates',
  // 'capacity',
] as const
export const ANALYTICS_SUPPLY_DEMAND_TABS = ['generation', 'consumption'] as const

export const SETTINGS_STAKEHOLDERS_TABS = [
  'offtakers',
  'epc-providers',
  'om-providers',
  'developers',
] as const

export const SOLAR_ASSET_TABS = ['project', 'simulation', 'operations'] as const

export const CASH_FLOW_AGGREGATION_KEYS = ['individual', 'cumulative'] as const
export const CASH_FLOW_BREAKDOWN_KEYS = [
  'gen',
  'mrv',
  'fit',
  'fip',
  'ppf',
  'opx',
  'cpx',
  'ncf',
] as const

export const CASH_FLOW_KEYS = [
  'gen',
  'mrv',
  'fit',
  'fip',
  'ppf',
  'opx',
  'opf',
  'cpx',
  'ncf',
  'ccf',
] as const

export const ENERGY_CONSUMPTION_API_CODES = ['dem', 'itc', 'phy', 'sol', 'win'] as const

export const ENERGY_SOURCE_API_CODES = [
  'nuc',
  'the',
  'hyd',
  'sol',
  'win',
  'bio',
  'geo',
  'phy',
  'itc',
] as const

export const GENERATION_KEYS = ['gen', 'cur'] as const
export const M_Y_RESOLUTION_KEYS = ['monthly', 'yearly'] as const
export const REVENUES_KEYS = ['mrv', 'fip', 'fit', 'ppf'] as const

export const OPEX_KEYS = ['otr', 'hwr', 'oem', 'asm', 'ins', 'lle', 'dec', 'tax'] as const

export const REGION_API_CODES = [
  'sys',
  'hkd',
  'toh',
  'tok',
  'chb',
  'hok',
  'kan',
  'chg',
  'shi',
  'kyu',
  'oki',
] as const

export const REGION_API_CODES_ALPHABETICAL = [
  'sys',
  'chb',
  'chg',
  'hkd',
  'hok',
  'kan',
  'kyu',
  'oki',
  'toh',
  'shi',
  'tok',
] as const

export const SPV_LEGAL_ENTITY_FORM_INITIAL_VALUES = {
  companyType: SpvCompanyType.Gk,
  legalEntityNameEn: '',
  legalEntityNameJa: '',
  financialYear: '' as '' | Month,
  companyRegistrationId: '',
  legalEntityCreationDate: '',
}

export const SPV_FINANCIAL_INFORMATION_FORM_INITIAL_VALUES = {
  couponPaymentFrequency: CouponPaymentFrequency.Annual,
  couponPaymentMonth: '' as Month | '',
  couponPayoutPercentage: '',
  loans: [],
  investments: [],
  targetDSCR: '',
}

export const SPV_ASSETS_FORM_INITIAL_VALUES = {
  assets: [],
}

export const NEW_SPV_CREATION_STEPS = ['legal-entity', 'financials', 'assets'] as const

const defaultFlexiblePrice: ValueAssumption[] = [{ time: '1', value: '0' }]

export const PPA_ENTITY_FORM_INITIAL_VALUES = {
  name: '',
  // BASIC CONTRACT TERMS
  type: undefined,
  status: PpaStatus.Draft,
  offtaker: '',
  startDate: '',
  term: '10',
  fipBeneficiary: FipBeneficiary.Ipp,
  // PRICING TERMS
  pricingScheme: PricingScheme.Fixed,
  strikePrice: '',
  discountToMarket: '',
  floorPrice: '0',
  capPrice: '',
  floatingShare: '',
  fixedShare: '',
  isFlexiblePricingScheme: false,
  // SETTLEMENT TERMS
  settlementMarket: SettlementMarket.DayAhead,
  settlementPricingZone: SettlementPricingZone.AssetGridArea,
  calculationInterval: '30',
  strikePriceFlexible: defaultFlexiblePrice,
  floorPriceFlexible: defaultFlexiblePrice,
  discountToMarketFlexible: defaultFlexiblePrice,
  capPriceFlexible: defaultFlexiblePrice,
  // PAYMENT TERMS
  billingFrequency: '1',
  paymentTerm: '30',
  paymentMode: PaymentTermMode.EndOfMonth,
  consumptionTax: '10',
}

export const PPA_ASSETS_FORM_INITIAL_VALUES = {
  assets: [],
}

export const NEW_PPA_CREATION_STEPS = ['ppa', 'assets'] as const

export const MONITORING_DATA_UPLOAD_STEPS = ['asset-selection', 'data-upload'] as const
export const DEMAND_DATA_UPLOAD_STEPS = ['asset-selection', 'data-upload'] as const

export const BG_ENTITY_FORM_INITIAL_VALUES = {
  balancingGroupCode: '',
  gridZone: '',
  contractClassificationNumber1: '',
  imbalanceValue: 0,
  name: '',
} as const

export const BG_ASSETS_FORM_INITIAL_VALUES = {
  assets: [],
}

export const NEW_BG_CREATION_STEPS = ['bg', 'assets'] as const

export const SOLAR_ASSET_MAP_LAYER_TYPES = [
  'map',
  'satellite',
  'terrain',
  // DE-SPECCED FOR NOW - SEE TC-10456
  // 'solar-irradiation',
  // 'snowfall',
  // 'hazard',
] as const
