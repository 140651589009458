import { bboxPolygon } from '@turf/bbox-polygon'
import { center as turfCenter } from '@turf/center'
import { CardinalBounds, Coordinates, Maybe } from 'typ'
import {
  SolarAssetsMapLayerType,
  SolarAssetsMapOverviewContent,
  SolarAssetsMapSelectedPointApiData,
  StoreSlice,
} from '../types'

export interface ControlledMapViewState {
  bounds: CardinalBounds | null
  zoom: number
  center: Coordinates | null
}

export interface SelectedPoint {
  coordinates: Coordinates
}

export interface SolarAssetsMapSlice {
  solarAssetsMapViewState: ControlledMapViewState
  solarAssetsMapSetViewState: (state: Omit<ControlledMapViewState, 'center'>) => void
  solarAssetsMapSelectedPoint: SelectedPoint | null
  solarAssetsMapSelectedPointApiData: SolarAssetsMapSelectedPointApiData | null
  solarAssetsMapSetSelectedPointApiData: (data: SolarAssetsMapSelectedPointApiData | null) => void
  solarAssetsMapActiveLayer: SolarAssetsMapLayerType
  solarAssetsMapSetActiveLayer: (layer: SolarAssetsMapLayerType) => void
  solarAssetsMapShowTransmissionLines: boolean
  solarAssetsMapSetShowTransmissionLines: (showTransmissionLines: boolean) => void
  solarAssetsMapOverviewContent: SolarAssetsMapOverviewContent
  solarAssetsMapOverviewPanelIsOpen: boolean
  solarAssetsMapSetOverviewPanelIsOpen: (isOpen: boolean) => void
  solarAssetsMapOpenOverviewPanel: VoidFunction
  solarAssetsMapCloseOverviewPanel: VoidFunction
  solarAssetsMapReset: VoidFunction
  solarAssetsMapOpenAsset: (id: string) => void
  solarAssetsMapCreateAsset: (point?: SelectedPoint) => void
}

const getCenter = (bounds: Maybe<CardinalBounds>) => {
  if (!bounds) return null
  const center = turfCenter(bboxPolygon([bounds.west, bounds.south, bounds.east, bounds.north]))
  return {
    lat: Number(center.geometry.coordinates[1].toFixed(14)),
    lon: Number(center.geometry.coordinates[0].toFixed(14)),
  }
}

export const createSolarAssetMapSlice: StoreSlice<SolarAssetsMapSlice> = (set, get) => ({
  solarAssetsMapViewBounds: undefined,
  solarAssetsMapViewState: {
    bounds: null,
    zoom: 6,
    center: { lat: 35.682839, lon: 139.759455 },
  },
  solarAssetsMapSetViewState: (state) =>
    set({ solarAssetsMapViewState: { ...state, center: getCenter(state.bounds) } }),
  solarAssetsMapSelectedPoint: null,
  solarAssetsMapSelectedPointApiData: null,
  solarAssetsMapSetSelectedPointApiData: (data) =>
    set({ solarAssetsMapSelectedPointApiData: data }),
  solarAssetsMapActiveLayer: 'map',
  solarAssetsMapSetActiveLayer: (layer) => set({ solarAssetsMapActiveLayer: layer }),
  solarAssetsMapShowTransmissionLines: false,
  solarAssetsMapSetShowTransmissionLines: (showTransmissionLines) =>
    set({ solarAssetsMapShowTransmissionLines: showTransmissionLines }),
  solarAssetsMapOverviewContent: null,
  solarAssetsMapOverviewPanelIsOpen: false,
  solarAssetsMapSetOverviewPanelIsOpen: (isOpen) =>
    set({ solarAssetsMapOverviewPanelIsOpen: isOpen }),

  solarAssetsMapOpenAsset: (id) => {
    set({
      solarAssetsMapOverviewContent: { type: 'asset', id },
      solarAssetsMapOverviewPanelIsOpen: true,
      solarAssetsMapSelectedPoint: null,
    })
  },
  solarAssetsMapCreateAsset: (point) => {
    const lat = point?.coordinates.lat ?? get().solarAssetsMapSelectedPoint?.coordinates.lat ?? 0
    const lon = point?.coordinates.lon ?? get().solarAssetsMapSelectedPoint?.coordinates.lon ?? 0
    set({
      solarAssetsMapSelectedPoint: { coordinates: { lat, lon } },
      solarAssetsMapOverviewContent: { type: 'new', lat, lon },
      solarAssetsMapOverviewPanelIsOpen: true,
    })
  },
  solarAssetsMapOpenOverviewPanel: () => set({ solarAssetsMapOverviewPanelIsOpen: true }),
  solarAssetsMapCloseOverviewPanel: () =>
    set({
      solarAssetsMapOverviewPanelIsOpen: false,
      solarAssetsMapOverviewContent: null,
      solarAssetsMapSelectedPoint: null,
    }),
  solarAssetsMapReset: () => {
    set({
      solarAssetsMapSelectedPoint: null,
      solarAssetsMapOverviewContent: null,
      solarAssetsMapOverviewPanelIsOpen: false,
      solarAssetsMapSelectedPointApiData: null,
    })
  },
})
