import { SolarAssetsViewType, StoreSlice } from '../types'

export interface SolarAssetsSlice {
  solarAssetsViewType: SolarAssetsViewType
  solarAssetsSetViewType: (viewType: SolarAssetsViewType) => void
}

export const createSolarAssetsSlice: StoreSlice<SolarAssetsSlice> = (set, _get) => ({
  solarAssetsViewType: 'list',
  solarAssetsSetViewType: (viewType) => set({ solarAssetsViewType: viewType }),
})
