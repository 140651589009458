import { lastOf } from 'array'
import { useCallback, useEffect, useRef } from 'react'
import { isEmpty, isNotNil } from 'typeguards'

type Navigated = {
  pathname: string
  url: string
}
interface useTrackNavigationHistoryArgs {
  setPrevPage: (args: Navigated | null) => void
  router: {
    pathname: string
    asPath: string
  }
}
export const useTrackNavigationHistory = ({
  setPrevPage,
  router,
}: useTrackNavigationHistoryArgs) => {
  const previousPaths = useRef<Navigated[]>([])
  const getPrevPage = useCallback(() => {
    if (isEmpty(previousPaths.current)) return null
    return previousPaths.current[previousPaths.current.length - 1]
  }, [])

  useEffect(() => {
    // We want to update the latest item in previous paths if pathname does not change
    // but we want to add a new item if pathname changes.
    // This is to prevent adding the same pathname multiple times and making the previous page
    // an accurate representation of the previous navigated page and not just a change in parameters.
    if (lastOf(previousPaths.current)?.pathname === router.pathname) {
      previousPaths.current.pop()
      previousPaths.current.push({ pathname: router.pathname, url: router.asPath })
    } else {
      setPrevPage(getPrevPage())
      previousPaths.current = [
        getPrevPage(),
        { pathname: router.pathname, url: router.asPath },
      ].filter(isNotNil)
    }
  }, [router.pathname, router.asPath, setPrevPage, getPrevPage])
}
