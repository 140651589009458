/**
 * Deeply merges two objects. Values from `source` will overwrite or add to `target`.
 * Arrays are replaced, not merged, and objects will be merged recursively.
 * If `source` and `target` have different types (e.g., object vs. array), the value from `source` replaces the value from `target`.
 * @param target - The base object to merge into.
 * @param source - The object with values to overwrite or add to the target.
 * @returns A new object with merged values.
 */
export function mergeDeep<T, S>(target: T, source: S): T & S {
  if (typeof target !== 'object' || target === null) return source as T & S
  if (typeof source !== 'object' || source === null) return target as T & S

  if (Array.isArray(target) || Array.isArray(source)) {
    // Replace arrays instead of merging
    return source as T & S
  }

  // Iterate over the source object's own properties
  const result: any = { ...target }

  Object.keys(source).forEach((key) => {
    const sourceValue = (source as any)[key]
    const targetValue = (target as any)[key]

    if (typeof sourceValue === 'object' && sourceValue !== null) {
      // Recursively merge objects
      result[key] = mergeDeep(targetValue, sourceValue)
    } else {
      // Overwrite or add the value
      result[key] = sourceValue
    }
  })

  return result as T & S
}
