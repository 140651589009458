import { StoreSlice } from '../types'

type RouterMethods = {
  push: (href: string, options?: Record<string, unknown>) => void | Promise<void>
  replace: (href: string, options?: Record<string, unknown>) => void | Promise<void>
}

type Navigated = {
  pathname: string
  url: string
} | null

export interface NavigationSlice {
  navigationPrevPage: Navigated | null
  setNavigationPrevPage: (value: Navigated) => void
  navigationRouter: RouterMethods
  setNavigationRouter: (router: RouterMethods) => void
}

export const createNavigationSlice: StoreSlice<NavigationSlice> = (set, _get) => ({
  navigationPrevPage: null,
  setNavigationPrevPage: (value) => set({ navigationPrevPage: value }),
  navigationRouter: {
    push: () => {},
    replace: () => {},
  },
  setNavigationRouter: (navigationRouter) => set({ navigationRouter }),
})
