import { TableLayoutV2 } from 'typ'
import { StoreSlice } from '../types'

type FilterState = Record<string, any>
type ReportingActualsField = 'date' | 'description' | 'amount' | 'account' | 'allocation'

export interface ReportingActualsFilterSlice {
  reportingActualsFilterSetState: (workspaceId: string) => (newState: FilterState) => void
  reportingActualsFilterState: Record<string, FilterState>
  reportingActualsFilterIsOpen: boolean
  reportingActualsFilterSetIsOpen: (isOpen: boolean) => void
  reportingActualsSelectedSpvIds: Record<string, string>
  reportingActualsSetSelectedSpvId: (workspaceId: string) => (spvId: string) => void
  reportingActualsLayout: TableLayoutV2<ReportingActualsField>
  setReportingActualsLayout: (layout: Partial<TableLayoutV2<ReportingActualsField>>) => void
}

export const createReportingActualsFilterSlice: StoreSlice<ReportingActualsFilterSlice> = (
  set
) => ({
  reportingActualsFilterSetState: (workspaceId) => (newState) =>
    set((state) => ({
      reportingActualsFilterState: {
        ...state.reportingActualsFilterState,
        [workspaceId]: newState,
      },
    })),
  reportingActualsFilterState: {},
  reportingActualsFilterIsOpen: false,
  reportingActualsFilterSetIsOpen: (isOpen) => set({ reportingActualsFilterIsOpen: isOpen }),
  reportingActualsSelectedSpvIds: {},
  reportingActualsSetSelectedSpvId: (workspaceId) => (spvId) =>
    set((state) => ({
      reportingActualsSelectedSpvIds: {
        ...state.reportingActualsSelectedSpvIds,
        [workspaceId]: spvId,
      },
    })),
  reportingActualsLayout: {
    displayedCols: ['date', 'description', 'amount', 'account', 'allocation'],
  },
  setReportingActualsLayout: (layout) =>
    set((state) => ({
      reportingActualsLayout: { ...state.reportingActualsLayout, ...layout },
    })),
})
