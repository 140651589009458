import { log } from 'logger'
import useTranslation from 'next-translate/useTranslation'
import { ReactNode } from 'react'
import store from 'store'
import { toLowerCase } from 'string'
import { isNil, isNotEmpty, isNotIncluded } from 'typeguards'
import { toast } from 'ui-v2/components/Toast'
import { handleAuthorizationProblem } from './handle-authorization-problem'
import { handleResourceAlreadyExistsProblem } from './handle-resource-already-exists-problem'
import { handleResourceDoesNotExistProblem } from './handle-resource-does-not-exist-problem'

export const crudErrorHandlerDict = {
  contactSupport: 'common:contact-support-short',
  genericServerError: 'errors:generic-server-error',
}

type HandledPropblems =
  | 'authorizationProblem'
  | 'resourceDoesNotExistProblem'
  | 'resourceAlreadyExistsProblem'

type Options = {
  description?: ReactNode
  title?: ReactNode
  toastId: string
  duration?: number
  cb?: VoidFunction
  ignoredProblems?: HandledPropblems[]
  additionalProblems?: string[]
}

function isProblem(problem: any): problem is {
  __typename: string
  message: string
} {
  return typeof problem === 'object' && '__typename' in problem && 'message' in problem
}

const handleProblems = ({
  problems,
  toastId,
  ignoredProblems,
  additionalProblems,
  handleGenericError,
}: {
  problems: any[]
  toastId: string
  ignoredProblems: HandledPropblems[]
  additionalProblems?: string[]
  handleGenericError: VoidFunction
}) => {
  problems.forEach((p) => {
    if (isNotIncluded('authorizationProblem', ignoredProblems))
      handleAuthorizationProblem([p], { toastId: `${toastId}-auth-error` })
    if (isNotIncluded('resourceDoesNotExistProblem', ignoredProblems))
      handleResourceDoesNotExistProblem([p], { toastId: `${toastId}-rdne-error` })
    if (isNotIncluded('resourceAlreadyExistsProblem', ignoredProblems))
      handleResourceAlreadyExistsProblem([p], { toastId: `${toastId}-rae-error` })
    if (
      isNotEmpty(additionalProblems) &&
      additionalProblems.map(toLowerCase).includes(toLowerCase(p.__typename)) &&
      isNotIncluded(toLowerCase(p.__typename), ignoredProblems.map(toLowerCase))
    ) {
      handleGenericError()
    }
  })
}

export const useCrudErrorHandler = (options: Options) => {
  const { t } = useTranslation()
  const openContactSupportModal = store.useOpenContactSupportModal()

  return (err: any | any[], optionsOverride?: Partial<Options>) => {
    if (isNil(err)) return

    let skipGenericToast = false

    const description =
      optionsOverride?.description ||
      options.description ||
      t(crudErrorHandlerDict.genericServerError)
    const title = optionsOverride?.title || options.title || undefined
    const toastId = optionsOverride?.toastId || options.toastId
    const duration = optionsOverride?.duration || options.duration || undefined
    const ignoredProblems = optionsOverride?.ignoredProblems || options.ignoredProblems || []
    const additionalProblems =
      optionsOverride?.additionalProblems || options.additionalProblems || []
    const cb = optionsOverride?.cb || options.cb || undefined

    const handleGenericError = () => {
      toast.error(
        {
          title,
          description,
          buttonProps: {
            onPress: openContactSupportModal,
            label: t(crudErrorHandlerDict.contactSupport),
          },
        },
        {
          toastId,
          duration,
        }
      )
    }

    if (Array.isArray(err) && isNotEmpty(err)) {
      const problems = err.filter(isProblem)
      const errors = err.filter((e) => !isProblem(e))
      if (err.every(isProblem)) skipGenericToast = true
      errors.forEach((e) => log.error(e))
      handleProblems({ problems, toastId, ignoredProblems, additionalProblems, handleGenericError })
    } else {
      log.error(err)
    }
    cb?.()
    if (!skipGenericToast) {
      handleGenericError()
    }
  }
}
